import { DataEncoding, tsDataObject } from "../../../services/types/gallus";

const dataArrayFormatted = [
  ["Size", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Originations",
    "text-underlined",
    { value: 28495976000, overrideEncoding: "$skb1d" },
    { value: 24661690000, overrideEncoding: "$skb1d" },
    0.155475395238526,
    { value: 6905527000, overrideEncoding: "$skb1d" },
    { value: 6525898000, overrideEncoding: "$skb1d" },
    0.0581726836674432,
    { value: 39509521000, overrideEncoding: "$skb1d" },
    { value: 33628993000, overrideEncoding: "$skb1d" },
    0.174864825717499,
    { value: 6659329000, overrideEncoding: "$skb1d" },
    { value: 6090634000, overrideEncoding: "$skb1d" },
    0.0933720528930158,
  ],
  [
    "Servicing",
    "CSSCLASSGOESHERE",
    { value: 546064899000, overrideEncoding: "$-skb1d" },
    { value: 534557627000, overrideEncoding: "$-skb1d" },
    0.0215267193259969,
    { value: 91485163000, overrideEncoding: "$-skb1d" },
    { value: 89092933000, overrideEncoding: "$-skb1d" },
    0.0268509512421147,
    { value: 212218975000, overrideEncoding: "$-skb1d" },
    { value: 189482798000, overrideEncoding: "$-skb1d" },
    0.11999071810202,
    { value: 114915206000, overrideEncoding: "$-skb1d" },
    { value: 114278549000, overrideEncoding: "$-skb1d" },
    0.00557109803695521,
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Balance Sheet", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Tangible Assets",
    "CSSCLASSGOESHERE",
    23884180000,
    22408895000,
    0.0658347946206184,
    4416218000,
    4349392000,
    0.0153644463410059,
    15119798000,
    12921641000,
    0.170114384078617,
    6417627000,
    5942777000,
    0.0799037217785559,
  ],
  [
    "Tangible Equity",
    "text-underlined",
    { value: 7118430000, overrideEncoding: "$-sk0d" },
    { value: 7574102000, overrideEncoding: "$-sk0d" },
    -0.060161851530386,
    { value: 929673000, overrideEncoding: "$-sk0d" },
    { value: 991984000, overrideEncoding: "$-sk0d" },
    -0.0628145212019549,
    { value: 2180527000, overrideEncoding: "$-sk0d" },
    { value: 2329012000, overrideEncoding: "$-sk0d" },
    -0.063754501908964,
    { value: 592049000, overrideEncoding: "$-sk0d" },
    { value: 578938000, overrideEncoding: "$-sk0d" },
    0.0226466391910705,
  ],
  [
    "TCE / TA",
    "CSSCLASSGOESHERE",
    { value: 0.298039539142646, overrideEncoding: "$%1d" },
    { value: 0.337995336226976, overrideEncoding: "$%1d" },
    { value: -0.03995579708433, overrideEncoding: "$%1d" },
    { value: 0.210513384982354, overrideEncoding: "$%1d" },
    { value: 0.228074176804482, overrideEncoding: "$%1d" },
    { value: -0.0175607918221284, overrideEncoding: "$%1d" },
    { value: 0.14421667538151, overrideEncoding: "$%1d" },
    { value: 0.180241193823602, overrideEncoding: "$%1d" },
    { value: -0.0360245184420921, overrideEncoding: "$%1d" },
    { value: 0.0922535697384719, overrideEncoding: "$%1d" },
    { value: 0.0974187656713351, overrideEncoding: "$%1d" },
    { value: -0.005165195932863, overrideEncoding: "$%1d" },
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Income Statement", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "Revenue",
    "CSSCLASSGOESHERE",
    646948000,
    1300722000,
    -0.502623927326516,
    224097000,
    285685000,
    -0.215580096959938,
    526434000,
    507094000,
    0.0381388854926306,
    314598000,
    265390000,
    0.185417687177362,
  ],
  [
    "Expense",
    "CSSCLASSGOESHERE",
    { value: 1144267000, overrideEncoding: "$-sk0d" },
    { value: 1108680000, overrideEncoding: "$-sk0d" },
    0.0320985315871125,
    { value: 217662000, overrideEncoding: "$-sk0d" },
    { value: 241158000, overrideEncoding: "$-sk0d" },
    -0.0974299007289826,
    { value: 494145000, overrideEncoding: "$-sk0d" },
    { value: 430022000, overrideEncoding: "$-sk0d" },
    0.149115626642358,
    { value: 311003000, overrideEncoding: "$-sk0d" },
    { value: 342547000, overrideEncoding: "$-sk0d" },
    -0.0920866333671001,
  ],
  [
    "Net Income",
    "SubTitleOrange",
    -481424000,
    177925000,
    -3.70576928481102,
    -66951000,
    37591000,
    -2.78103801441834,
    31945000,
    76286000,
    -0.58124688671578,
    2672000,
    -65853000,
    { value: "NM", overrideEncoding: "string" },
  ],
  [
    null,
    "Spacing",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ],
  ["Profitability", "Title", "", "", "", "", "", "", "", "", "", "", "", ""],
  [
    "GoS Margins",
    "CSSCLASSGOESHERE",
    { value: 0.0278, overrideEncoding: "$%2d" },
    { value: 0.0299, overrideEncoding: "$%2d" },
    { value: -0.0021, overrideEncoding: "$%2d" },
    { value: 0.0321, overrideEncoding: "$%2d" },
    { value: 0.0315, overrideEncoding: "$%2d" },
    { value: 0.000599999999999996, overrideEncoding: "$%2d" },
    { value: 0.0118, overrideEncoding: "$%2d" },
    { value: 0.0106, overrideEncoding: "$%2d" },
    { value: 0.0012, overrideEncoding: "$%2d" },
    { value: 0.0329, overrideEncoding: "$%2d" },
    { value: 0.0306, overrideEncoding: "$%2d" },
    { value: 0.0023, overrideEncoding: "$%2d" },
  ],
  [
    "Efficiency Ratio",
    "CSSCLASSGOESHERE",
    { value: 1.76871556910293, overrideEncoding: "$%1d" },
    { value: 0.852357383053412, overrideEncoding: "$%1d" },
    { value: 0.916358186049515, overrideEncoding: "$%1d" },
    { value: 0.971284756154701, overrideEncoding: "$%1d" },
    { value: 0.844139524301241, overrideEncoding: "$%1d" },
    { value: 0.12714523185346, overrideEncoding: "$%1d" },
    { value: 0.93866467591379, overrideEncoding: "$%1d" },
    { value: 0.848012400067838, overrideEncoding: "$%1d" },
    { value: 0.0906522758459523, overrideEncoding: "$%1d" },
    { value: 0.988572718199098, overrideEncoding: "$%1d" },
    { value: 1.29073062285693, overrideEncoding: "$%1d" },
    { value: -0.302157904657829, overrideEncoding: "$%1d" },
  ],
  [
    "Net Margin",
    "CSSCLASSGOESHERE",
    { value: -0.744146361067659, overrideEncoding: "$%1d" },
    { value: 0.136789413879369, overrideEncoding: "$%1d" },
    { value: -0.880935774947028, overrideEncoding: "$%1d" },
    { value: -0.298759019531721, overrideEncoding: "$%1d" },
    { value: 0.131581987153683, overrideEncoding: "$%1d" },
    { value: -0.430341006685404, overrideEncoding: "$%1d" },
    { value: 0.0606818708518067, overrideEncoding: "$%1d" },
    { value: 0.150437591452472, overrideEncoding: "$%1d" },
    { value: -0.0897557206006656, overrideEncoding: "$%1d" },
    { value: 0.00849337885174095, overrideEncoding: "$%1d" },
    { value: -0.248136704472663, overrideEncoding: "$%1d" },
    { value: 0.256630083324404, overrideEncoding: "$%1d" },
  ],
  [
    "ROTCE",
    "SubTitleOrange",
    { value: -0.270522573095472, overrideEncoding: "$%1d" },
    { value: 0.0939649347209742, overrideEncoding: "$%1d" },
    { value: -0.364487507816446, overrideEncoding: "$%1d" },
    { value: -0.288062576841535, overrideEncoding: "$%1d" },
    { value: 0.151579057726738, overrideEncoding: "$%1d" },
    { value: -0.439641634568272, overrideEncoding: "$%1d" },
    { value: 0.0586005126283692, overrideEncoding: "$%1d" },
    { value: 0.13101864653338, overrideEncoding: "$%1d" },
    { value: -0.0724181339050106, overrideEncoding: "$%1d" },
    { value: 0.0180525598387971, overrideEncoding: "$%1d" },
    { value: -0.454991726229752, overrideEncoding: "$%1d" },
    { value: 0.473044286068549, overrideEncoding: "$%1d" },
  ],
];

const columnFormattingFormatted: DataEncoding[] = [
  "string",
  "string",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
  "$sk0d",
  "$sk0d",
  "$%1d",
];

export const GrateBenchmarkQ324: tsDataObject = {
  data: dataArrayFormatted,
  columnNames: [
    "RowName",
    "CSSClass",
    "Q3 2024 Rocket",
    "Q2 2024 Rocket",
    "QoQ Rocket",
    "Q3 2024 Guild",
    "Q2 2024 Guild",
    "QoQ Guild",
    "Q3 2024 UWM",
    "Q2 2024 UWM",
    "QoQ UWM",
    "Q3 2024 Loan Depot",
    "Q2 2024 Loan Depot",
    "QoQ Loan Depot",
  ],
  columnDisplayName: [
    "RowName",
    "CSSClass",
    "Q3 2024",
    "Q2 2024",
    "QoQ",
    "Q3 2024",
    "Q2 2024",
    "QoQ",
    "Q3 2024",
    "Q2 2024",
    "QoQ",
    "Q3 2024",
    "Q2 2024",
    "QoQ",
  ],
  columnShow: [
    true,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ],
  columnGroupName: [
    {
      nameOfGroup: "Rocket",
      headerClass: "grate-group",
      columnsToGroup: ["Q3 2024 Rocket", "Q2 2024 Rocket", "QoQ Rocket"],
    },
    {
      nameOfGroup: "Guild",
      headerClass: "grate-group",
      columnsToGroup: ["Q3 2024 Guild", "Q2 2024 Guild", "QoQ Guild"],
    },
    {
      nameOfGroup: "UWM",
      headerClass: "grate-group",
      columnsToGroup: ["Q3 2024 UWM", "Q2 2024 UWM", "QoQ UWM"],
    },
    {
      nameOfGroup: "Loan Depot",
      headerClass: "grate-group",
      columnsToGroup: [
        "Q3 2024 Loan Depot",
        "Q2 2024 Loan Depot",
        "QoQ Loan Depot",
      ],
    },
  ],
  columnFormatting: columnFormattingFormatted,
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};
