/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { logout } from "@thoughtspot/visual-embed-sdk";
import { clearUserSession, getUserSessionItem, initTs } from "../..";
import { Auth } from "aws-amplify";
import { CognitoOidcConfig } from "../../CognitoOidcConfig";
import { useLazyAddUserActivityQuery } from "../../services/gallus";
import { environmentConfig } from "../../environment";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

interface SignOutProps {}

/**
 * This component redirects to the Cognito logout page
 */
export const SignOut = (props: SignOutProps) => {
  const [addUserActivityQuery] = useLazyAddUserActivityQuery();
  const navigate = useNavigate();
  const [currentQueryParameters] = useSearchParams();
  const [logOutMessage, setLogOutMessage] = useState<string>(
    "Logging out, please wait...",
  );

  useEffect(() => {
    const init = async () => {
      const redirect = () => {
        if (environmentConfig.useCognitoOidcLogin ?? false) {
          let origin = window.location.origin;
          let logoutUri = encodeURIComponent(`${origin}`);
          let redirectUri = encodeURIComponent(`${origin}`);
          window.location.href = `${CognitoOidcConfig.logoutEndpoint}?response_type=code&client_id=${CognitoOidcConfig.clientId}&logout_uri=${logoutUri}&redirect_uri=${redirectUri}`;
        } else {
          navigate("/");
        }
      };

      try {
        // No need to signout for token flow
        await initTs("TOKEN", getUserSessionItem("userName"), 0);
        // init({
        //     ...tsConfig,
        //     thoughtSpotHost: "https://gallus.thoughtspot.cloud",
        //     authType: AuthType.OIDCRedirect
        // });

        // clear sessionStorage
        clearUserSession();
        addUserActivityQuery({
          URL: window.location.pathname,
          ID_ActivityType: "3",
        });

        logout().finally(async () => {
          await Auth.signOut();
          redirect();
        });
      } catch (ex) {
        console.log(ex);
        clearUserSession();
        await Auth.signOut();
        redirect();
      }
    };
    const errorCode = currentQueryParameters.get("error_code");
    if (errorCode) {
      setLogOutMessage("Error Code: " + errorCode + ". Please log in again.");
    }
    setTimeout(
      () => {
        init();
      },
      errorCode === null ? 0 : 1500,
    );
  }, []);

  return <div className="loading-div">{logOutMessage}</div>;
};
