import { tsDataObject } from "../../../services/types/gallus";

export const GrateMBAMoneyQ32024: tsDataObject = {
  data: [
    [null, "Spacing", null, null, null, null, null],
    [
      "Total Production Revenue",
      "SubTitleBlue",
      null,
      { value: "11416.934612", overrideEncoding: "$sk0d-S" },
      { value: "10504.190087", overrideEncoding: "$sk0d-S" },
      { value: "12164.11325", overrideEncoding: "$sk0d-S" },
      { value: "12653.843416", overrideEncoding: "$sk0d-S" },
    ],
    ["Expenses", "Title", null, null, null, null, null],
    [
      "Sales Personnel",
      null,
      null,
      { value: "3144.1163613", overrideEncoding: "$sk0d-S" },
      { value: "2748.4798076", overrideEncoding: "$sk0d-S" },
      { value: "3507.0657783", overrideEncoding: "$sk0d-S" },
      { value: "3586.9421668", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Fulfillment Personnel",
      null,
      null,
      1403.063831,
      1305.2036245,
      1450.0396025,
      1361.1666493,
    ],
    [
      "Production Support Employees",
      null,
      null,
      755.48414642,
      497.87907385,
      828.67846282,
      790.3116889,
    ],
    [
      "Management and Directors",
      null,
      null,
      481.57870074,
      477.92693195,
      487.63739557,
      563.47778324,
    ],
    [
      "Benefits",
      null,
      null,
      811.5620161,
      746.27619563,
      919.31608091,
      949.54116925,
    ],
    [
      "Personnel",
      "SubTitleBlue",
      null,
      { value: "6595.80505556", overrideEncoding: "$sk0d-S" },
      { value: "5775.76563353", overrideEncoding: "$sk0d-S" },
      { value: "7192.7373201", overrideEncoding: "$sk0d-S" },
      { value: "7251.43945749", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Occupancy and Equipment",
      null,
      null,
      526.14990182,
      352.03624907,
      572.73951832,
      463.03554064,
    ],
    [
      "Technology-Related Expenses",
      null,
      null,
      447.21816621,
      344.84589097,
      424.59267068,
      358.2353704,
    ],
    [
      "Other Direct Expenses",
      null,
      null,
      2299.3346203,
      1995.3017322,
      2358.6094571,
      2116.8905485,
    ],
    [
      "Direct Loan Production Expenses",
      "SubTitleBlue",
      null,
      { value: "9868.50774389", overrideEncoding: "$sk0d-S" },
      { value: "8467.94950577", overrideEncoding: "$sk0d-S" },
      { value: "10548.6789662", overrideEncoding: "$sk0d-S" },
      { value: "10189.60091703", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Corporate Allocation",
      "SubTitleBlue",
      null,
      847.10677757,
      732.70833831,
      806.23456335,
      707.05302974,
    ],
    [
      "Total Expenses",
      "SubTitlePurple",
      null,
      { value: "10715.61452146", overrideEncoding: "$sk0d-S" },
      { value: "9200.65784408", overrideEncoding: "$sk0d-S" },
      { value: "11354.91352955", overrideEncoding: "$sk0d-S" },
      { value: "10896.65394677", overrideEncoding: "$sk0d-S" },
    ],
    [
      "Total Net Production Income",
      "SubTitlePurple",
      null,
      701.320090539999,
      1303.53224292,
      809.19972045,
      1757.18946923,
    ],
    [null, "Spacing", null, null, null, null, null],
    ["Memo", "Title", null, null, null, null, null],
    [
      "Avg. Pull-Through (Closings/Applications #)",
      "SubTitleOrange",
      null,
      { value: "72.4", overrideEncoding: "string" },
      { value: "73.8", overrideEncoding: "string" },
      { value: "73.7", overrideEncoding: "string" },
      { value: "75.9", overrideEncoding: "string" },
    ],
    [
      "Closings per Sales Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "3.2", overrideEncoding: "string" },
      { value: "4.5", overrideEncoding: "string" },
      { value: "3.0", overrideEncoding: "string" },
      { value: "3.6", overrideEncoding: "string" },
    ],
    [
      "Closings per Fulfillment Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "5.5", overrideEncoding: "string" },
      { value: "6.4", overrideEncoding: "string" },
      { value: "5.3", overrideEncoding: "string" },
      { value: "5.6", overrideEncoding: "string" },
    ],
    [
      "Closings per Prod Support Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "10.4", overrideEncoding: "string" },
      { value: "18.8", overrideEncoding: "string" },
      { value: "9.2", overrideEncoding: "string" },
      { value: "15.3", overrideEncoding: "string" },
    ],
    [
      "Closings per Total Prod. Employee per Mo.",
      "SubTitleOrange",
      null,
      { value: "1.5", overrideEncoding: "string" },
      { value: "1.8", overrideEncoding: "string" },
      { value: "1.5", overrideEncoding: "string" },
      { value: "1.7", overrideEncoding: "string" },
    ],
  ],
  columnNames: [
    "RowName",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnDisplayName: [
    "($ per Loan)",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnShow: [true, false, true, true, true, true, true],
  columnGroupName: [
    {
      nameOfGroup: "Income Statement for Q3 2024 ($ per Loan)",
      headerClass: "grate-group",
      columnsToGroup: [
        "G-Rate",
        "Total Industry",
        "Tot. Ind. >1B",
        "Retail / CD",
        "Ret. / CD > 500m",
      ],
    },
  ],
  columnFormatting: [
    "string",
    "string",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
    "$-sk0d-S",
  ],
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};

export const GrateMBABasisPointsQ32024: tsDataObject = {
  data: [
    [null, "Spacing", null, null, null, null, null],
    [
      "Total Production Revenue",
      "SubTitleBlue",
      null,
      341.01744845,
      300.33658804,
      361.38654933,
      365.25511696,
    ],
    ["Expenses", "Title", null, null, null, null, null],
    [
      "Sales Personnel",
      null,
      null,
      92.934746366,
      81.122393837,
      103.71046305,
      104.19823556,
    ],
    [
      "Fulfillment Personnel",
      null,
      null,
      42.468034938,
      36.995403129,
      43.315691306,
      39.372001868,
    ],
    [
      "Production Support Employees",
      null,
      null,
      23.577507123,
      14.858132273,
      25.773213071,
      24.587431635,
    ],
    [
      "Management and Directors",
      null,
      null,
      14.646223367,
      12.687765,
      14.970823559,
      15.981513021,
    ],
    [
      "Benefits",
      null,
      null,
      24.711554467,
      21.551210708,
      27.346812897,
      27.523418188,
    ],
    [
      "Personnel",
      "SubTitleBlue",
      null,
      198.338066261,
      167.214904947,
      215.117003883,
      211.662600272,
    ],
    [
      "Occupancy and Equipment",
      null,
      null,
      16.450198364,
      10.491785019,
      17.521173058,
      13.890882193,
    ],
    [
      "Technology-Related Expenses",
      null,
      null,
      13.930431175,
      10.126254177,
      13.293874402,
      10.675741198,
    ],
    [
      "Other Direct Expenses",
      null,
      null,
      66.290424258,
      57.984463027,
      66.644724664,
      62.253333697,
    ],
    [
      "Direct Loan Production Expenses",
      "SubTitleBlue",
      null,
      295.009120058,
      245.81740717,
      312.576776007,
      298.48255736,
    ],
    [
      "Corporate Allocation",
      "SubTitleBlue",
      null,
      27.928778522,
      21.871716905,
      27.244532685,
      21.771367047,
    ],
    [
      "Total Expenses",
      "SubTitlePurple",
      null,
      322.93789858,
      267.689124075,
      339.821308692,
      320.253924407,
    ],
    [
      "Total Net Production Income",
      "SubTitlePurple",
      null,
      18.07954987,
      32.647463965,
      21.565240638,
      45.001192553,
    ],
    [null, "Spacing", null, null, null, null, null],
    ["Memo", "Title", null, null, null, null, null],
    [
      "Avg. Pull-Through (Closings/Applications #)",
      "SubTitleOrange",
      null,
      72.416630435,
      73.849677419,
      73.668478261,
      75.895777778,
    ],
    [
      "Closings per Sales Employee per Mo.",
      "SubTitleOrange",
      null,
      3.2416693453,
      4.4977163369,
      3.0245098039,
      3.6351706037,
    ],
    [
      "Closings per Fulfillment Employee per Mo.",
      "SubTitleOrange",
      null,
      5.5416666667,
      6.4347015968,
      5.2903846154,
      5.6425371256,
    ],
    [
      "Closings per Prod Support Employee per Mo.",
      "SubTitleOrange",
      null,
      10.352941176,
      18.820833333,
      9.1722222222,
      15.257309942,
    ],
    [
      "Closings per Total Prod. Employee per Mo.",
      "SubTitleOrange",
      null,
      1.5305040591,
      1.8071390799,
      1.4735202492,
      1.7256097561,
    ],
  ],
  columnNames: [
    "RowName",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnDisplayName: [
    "Basis Points",
    "CSSClass",
    "G-Rate",
    "Total Industry",
    "Tot. Ind. >1B",
    "Retail / CD",
    "Ret. / CD > 500m",
  ],
  columnShow: [true, false, true, true, true, true, true],
  columnGroupName: [
    {
      nameOfGroup: "Income Statement for Q3 2024 (basis points)",
      headerClass: "grate-group",
      columnsToGroup: [
        "G-Rate",
        "Total Industry",
        "Tot. Ind. >1B",
        "Retail / CD",
        "Ret. / CD > 500m",
      ],
    },
  ],
  columnFormatting: [
    "string",
    "string",
    "$-s0d",
    "$-s0d",
    "$-s0d",
    "$-s0d",
    "$-s0d",
  ],
  columnHeaderClasses: [
    "grid-bold",
    "",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
    "grate-column",
  ],
  columnDataClasses: [
    "text-left",
    "",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
    "text-right",
  ],
  tableTitle: "",
};
