/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "react-input-slider";
import "./Projections.scss";

interface ProjectionsProps {
  firstStartingValue: number;
  secondStartingValue: number;
  thirdStartingValue: number;
  fourthStartingValue: number;
  fifthStartingValue: number;
  sixthStartingValue: number;
  seventhStartingValue: number;
  eightStartingValue: number;
  ninthStartingValue: number;
  measure: string;
  symbol: string;
  title: string;
  firstLabel: string;
  secondLabel: string;
  thirdLabel: string;
  fourthLabel: string;
  fifthLabel: string;
  sixthLabel: string;
  seventhLabel: string;
  eigthLabel: string;
  ninthLabel: string;
  showLabels: boolean;
  onValuesChange: (
    firstValue: number,
    secondValue: number,
    thirdValue: number,
    fourthValue: number,
    fifthValue: number,
    sixthValue: number,
    seventhValue: number,
    eigthValue: number,
    ninthValue: number,
  ) => void;
}

interface ProjectionsState {
  firstValue: number;
  secondValue: number;
  thirdValue: number;
  fourthValue: number;
  fifthValue: number;
  sixthValue: number;
  seventhValue: number;
  eightValue: number;
  ninthValue: number;
}

export const Projections = (props: ProjectionsProps) => {
  const [state, setState] = useState<ProjectionsState>({
    firstValue: props.firstStartingValue,
    secondValue: props.secondStartingValue,
    thirdValue: props.thirdStartingValue,
    fourthValue: props.fourthStartingValue,
    fifthValue: props.fifthStartingValue,
    sixthValue: props.sixthStartingValue,
    seventhValue: props.seventhStartingValue,
    eightValue: props.eightStartingValue,
    ninthValue: props.ninthStartingValue,
  });

  useEffect(() => {
    props.onValuesChange(
      state.firstValue,
      state.secondValue,
      state.thirdValue,
      state.fourthValue,
      state.fifthValue,
      state.sixthValue,
      state.seventhValue,
      state.eightValue,
      state.ninthValue,
    );
  }, [state]);

  function handleChange(value: number, numberOfBoxSlider: number) {
    switch (numberOfBoxSlider) {
      case 1:
        setState({ ...state, firstValue: value });
        break;
      case 2:
        setState({ ...state, secondValue: value });
        break;
      case 3:
        setState({ ...state, thirdValue: value });
        break;
      case 4:
        setState({ ...state, fourthValue: value });
        break;
      case 5:
        setState({ ...state, fifthValue: value });
        break;
      case 6:
        setState({ ...state, sixthValue: value });
        break;
      case 7:
        setState({ ...state, seventhValue: value });
        break;
      case 8:
        setState({ ...state, eightValue: value });
        break;
      case 9:
        setState({ ...state, ninthValue: value });
        break;
    }
  }

  return (
    <div className="projections">
      <div className="projections-title">{props.title}</div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.firstLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.firstValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 1)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.firstValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 1);
              }}
              xmin={-10}
              xmax={20}
              xstep={0.5}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.secondLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.secondValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 2)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.secondValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 2);
              }}
              xmin={80}
              xmax={120}
              xstep={5}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.thirdLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.thirdValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 3)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.thirdValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 3);
              }}
              xmin={0}
              xmax={100}
              xstep={5}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.fourthLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.fourthValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 4)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.fourthValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 4);
              }}
              xmin={0}
              xmax={30}
              xstep={1}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.fifthLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.fifthValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 5)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.fifthValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 5);
              }}
              xmin={100}
              xmax={300}
              xstep={10}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.sixthLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <div className="input-group-text">{props.symbol}</div>
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.sixthValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 6)}
            />
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.sixthValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 6);
              }}
              xmin={0}
              xmax={10000}
              xstep={50}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.seventhLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <div className="input-group-text">{props.symbol}</div>
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.seventhValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 7)}
            />
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.seventhValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 7);
              }}
              xmin={0}
              xmax={10000}
              xstep={5}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.eigthLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.eightValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 8)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.eightValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 8);
              }}
              xmin={0}
              xmax={25}
              xstep={1}
            />
          </div>
        </div>
      </div>
      <div className="projections-input">
        <div
          className="projections-label"
          style={{ visibility: props.showLabels ? "visible" : "hidden" }}
        >
          {props.ninthLabel}
        </div>
        <div className="projections-input-box">
          <div className="input-group input-group-sm">
            <input
              className="form-control projections-input-box-input"
              type="number"
              value={state.ninthValue}
              onChange={(e) => handleChange(e.target.valueAsNumber, 9)}
            />
            <div className="input-group-text">{props.measure}</div>
          </div>
          {/* <br></br> */}
          <div className="projections-slider">
            <Slider
              styles={{
                track: {
                  backgroundColor: "#4bb5eb",
                },
                active: {
                  backgroundColor: "#4bb5eb",
                },
              }}
              axis="x"
              x={state.ninthValue}
              onChange={({ x }) => {
                x = Math.round(x * 100) / 100;
                handleChange(x, 9);
              }}
              xmin={0}
              xmax={100}
              xstep={0.1}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
