/* eslint-disable react-hooks/exhaustive-deps */
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { API } from "aws-amplify";
import React from "react";
import "./QuickSightDashboardEmbedWrapper.scss";

interface QuickSightDashboardEmbedWrapperProps {
  accountId?: string;
  initialDashboardId?: string;
  authorizedResourceArns?: string[];
}

export const QuickSightDashboardEmbedWrapper = (
  props: QuickSightDashboardEmbedWrapperProps,
) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>();
  React.useEffect(() => {
    const getUrl = async () => {
      let data = await API.post(
        "ServerlessAPI",
        "/embed/quicksight/dashboard",
        {
          body: {
            accessToken: "",
            accountId: props.accountId ?? "482021788001",
            region: "us-east-1",
            initialDashboardId:
              props.initialDashboardId ??
              "c905a6f4-aa8a-41a2-8995-0414e18405ab",
            namespace: "default",
            authorizedResourceArns: props.authorizedResourceArns ?? [
              "arn:aws:quicksight:us-east-1:482021788001:dashboard/c905a6f4-aa8a-41a2-8995-0414e18405ab",
            ],
          },
        },
      );
      return Promise.resolve(data);
    };

    const containerDiv = document.getElementById("embeddingContainer")!;
    containerDiv.innerHTML = "";
    setIsLoading(true);
    getUrl().then((data) => {
      setIsLoading(false);
      setData(data);
    });
  }, [props.initialDashboardId, props.authorizedResourceArns]);

  React.useEffect(() => {
    if (data) {
      const containerDiv = document.getElementById("embeddingContainer")!;
      const options = {
        url: data.EmbedUrl,
        container: containerDiv,
        scrolling: "no",
        height: "98%",
        width: "100%",
        footerPaddingEnabled: true,
      };
      embedDashboard(options);
    }
  }, [data]);

  return (
    <>
      <div style={{ height: "100%", width: "100%" }}>
        {isLoading ? (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        ) : (
          <div
            id="embeddingContainer"
            className="quicksight-dashboard-embed-container"
          ></div>
        )}
      </div>
    </>
  );
};
