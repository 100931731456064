/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import "../Module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Table } from "reactstrap";
import { ResultFromMultipleInputSliders } from "../../../components/generic-financial-model/MultipleInputSliders";
import Switch from "react-switch";
import {
  useLazyXlsxProcessorQuery,
  useXlsxProcessorQuery,
} from "../../../services/serverless";
import { Error } from "../../../components/Error";

function tableStringFormatTable(data: number, type: string) {
  var result;
  var processedData: number;
  processedData = Math.round(data / 100) / 10;
  switch (type) {
    case "subtotal":
      if (processedData < 0) {
        result = "($" + (-1 * processedData).toString() + ")";
      } else {
        result = "$" + processedData.toString();
      }
      break;

    case "sum":
      if (processedData < 0) {
        result = "(" + (-1 * processedData).toString() + ")";
      } else {
        result = processedData.toString();
      }
      break;
    case "ratio":
      processedData = Math.round(data * 10) / 10;
      result = processedData.toString() + "x";
      break;
  }
  return result;
}

interface EbitdaFinancialModelingProps {}

interface EbitdaFinancialModelingState {
  netIncomeChartOptions: Highcharts.Options;
  pfNetDebtChartOptions: Highcharts.Options;
  netDebtPFNetDebtChartOptions: Highcharts.Options;
  coreEBITDAAdjEBITDAChartOptions: Highcharts.Options;
  netDebtCoreAdjEBITDAAdjEBITDACovenantChartOptions: Highcharts.Options;
  covenantCushionChartOptions: Highcharts.Options;
  EBITDALTMQ42022ChartOptions: Highcharts.Options;
  Q42022PFNetDebtChartOptions: Highcharts.Options;
  LTMEBITDACreditEBITDAChartOptions: Highcharts.Options;
  Q4NetDebtPFNetDebtChartOptions: Highcharts.Options;
  LTMLeverageRatioSummaryChartOptions: Highcharts.Options;
  LTMCreditEBITDACushionChartOptions: Highcharts.Options;
  firstTableValues: number[];
  secondTableValues: number[];
  chartsLoadedFlag: boolean;
  Q4ChartsLoadedFlag: boolean;
  Q4Mode: boolean;
  inputValues: number[];
  initialInputValues: number[];
  initialDataLoaded: boolean;
  exceeded25Flag: number;
  errorFlag: boolean;
  errorCode: string;
  errorType: string;
  errorMessage: string;
}

export const EbitdaFinancialModeling = (
  props: EbitdaFinancialModelingProps,
) => {
  const [state, setState] = useState<EbitdaFinancialModelingState>({
    netIncomeChartOptions: {},
    pfNetDebtChartOptions: {},
    netDebtPFNetDebtChartOptions: {},
    coreEBITDAAdjEBITDAChartOptions: {},
    netDebtCoreAdjEBITDAAdjEBITDACovenantChartOptions: {},
    covenantCushionChartOptions: {},
    firstTableValues: [],
    secondTableValues: [],
    chartsLoadedFlag: false,
    EBITDALTMQ42022ChartOptions: {},
    Q42022PFNetDebtChartOptions: {},
    LTMEBITDACreditEBITDAChartOptions: {},
    Q4NetDebtPFNetDebtChartOptions: {},
    LTMLeverageRatioSummaryChartOptions: {},
    LTMCreditEBITDACushionChartOptions: {},
    Q4Mode: false,
    Q4ChartsLoadedFlag: false,
    inputValues: [],
    initialInputValues: [],
    initialDataLoaded: false,
    exceeded25Flag: 0,
    errorFlag: false,
    errorCode: "0",
    errorType: "",
    errorMessage: "",
  });
  const xlsxProcessorQueryForInputInitResult = useXlsxProcessorQuery({
    DataSource:
      "https://docs.google.com/spreadsheets/d/11tGVBAlviN0zg-p2-WyMIRoFMe4oClcK/",
    InputSheetName: null,
    Inputs: [],
    OutputSheetName: null,
    Outputs: [
      { SheetName: "Inputs", RowIndex: 8, CellIndex: 3, Value: null },
      { SheetName: "Inputs", RowIndex: 9, CellIndex: 3, Value: null },
      { SheetName: "Inputs", RowIndex: 10, CellIndex: 3, Value: null },
      { SheetName: "Inputs", RowIndex: 11, CellIndex: 3, Value: null },
      { SheetName: "Inputs", RowIndex: 12, CellIndex: 3, Value: null },
    ],
  });
  const [xlsxProcessorQueryForTLMQ22022, xlsxProcessorQueryForTLMQ22022Result] =
    useLazyXlsxProcessorQuery();
  const [xlsxProcessorQueryForTLMQ42022, xlsxProcessorQueryForTLMQ42022Result] =
    useLazyXlsxProcessorQuery();

  useEffect(() => {
    if (
      xlsxProcessorQueryForInputInitResult.isSuccess &&
      xlsxProcessorQueryForInputInitResult.status === "fulfilled"
    ) {
      const data = xlsxProcessorQueryForInputInitResult.data;
      let inputValuesArray: number[] = [
        data.Outputs[0].Value,
        data.Outputs[1].Value,
        data.Outputs[2].Value,
        data.Outputs[3].Value,
        data.Outputs[4].Value,
      ];
      setState({
        ...state,
        inputValues: inputValuesArray,
        initialInputValues: inputValuesArray,
        initialDataLoaded: true,
        errorFlag: false,
      });
    } else if (xlsxProcessorQueryForInputInitResult.isError) {
      console.log("FAILED to initialize parameters");
      console.log(xlsxProcessorQueryForInputInitResult.error);
      setState({
        ...state,
        errorFlag: true,
        errorCode: "500",
        errorType: "Internal",
        errorMessage: "FAILED to initialize parameters",
      });
    }
  }, [xlsxProcessorQueryForInputInitResult]);

  useEffect(() => {
    if (state.initialDataLoaded) {
      getValuesTLMQ22022();
    }
  }, [state.initialDataLoaded]);

  useEffect(() => {
    if (
      xlsxProcessorQueryForTLMQ22022Result.isSuccess &&
      xlsxProcessorQueryForTLMQ22022Result.status === "fulfilled"
    ) {
      const data = xlsxProcessorQueryForTLMQ22022Result.data;
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
          thousandsSep: ",",
        },
      });
      // quarter format function

      Highcharts.dateFormats = {
        q: function (timestamp) {
          var date = new Date(timestamp);
          const quarter = Math.floor(date.getUTCMonth() / 3) + 1;
          return quarter.toString(10);
        },
      };

      HC_more(Highcharts);
      const netIncomeChartData: any[] = [];
      for (let i = 0; i < 9; i++) {
        netIncomeChartData.push(data.Outputs[i].Value); //waterfall values shouldn't be rounded here, messes with final sum and intermediate sum. Round up in formatter
      }

      setState((prevState) => ({
        ...prevState,
        netIncomeChartOptions: {
          chart: {
            type: "waterfall",
          },

          tooltip: {
            enabled: false,
          },

          title: {
            text: "EBITDA LTM Q2 2022 (In Millions)",
            align: "left",
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: "#000000",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label;
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "Net Income",
                  y: netIncomeChartData[0],
                  color: "#ff0000",
                },
                {
                  name: "Int. Exp.",
                  y: netIncomeChartData[1],
                },
                {
                  name: "D&A",
                  y: netIncomeChartData[2],
                },
                {
                  name: "Tax",
                  y: netIncomeChartData[3],
                  color: "#ff0000",
                },
                {
                  name: "EBITDA",
                  isIntermediateSum: true, //netIncomeChartData[4]
                  color: "#9c9c9c",
                },
                {
                  name: "RR Synergies",
                  y: netIncomeChartData[5],
                  color: "#ff0000",
                },
                {
                  name: "Oper. Inv.",
                  y: netIncomeChartData[6],
                },
                {
                  name: "Other",
                  y: netIncomeChartData[7],
                },
                {
                  name: "Credit EBITDA", //netIncomeChartData[8]
                  isSum: true,
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + (Math.round(this.y! / 100) / 10) * -1 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
      }));

      const pfNetDebtData: any[] = [];
      for (let i = 9; i < 14; i++) {
        pfNetDebtData.push(data.Outputs[i].Value); //waterfall values shouldn't be rounded here, messes with final sum and intermediate sum. Round up in formatter
      }

      setState((prevState) => ({
        ...prevState,
        pfNetDebtChartOptions: {
          chart: {
            type: "waterfall",
          },

          title: {
            text: "PF Net Debt (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#aaa",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label;
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "Debt",
                  y: pfNetDebtData[0],
                },
                {
                  name: "Cash",
                  y: pfNetDebtData[1],
                  color: "#ff0000",
                },
                {
                  name: "Net Debt",
                  isIntermediateSum: true, //pfNetDebtData[2]
                  color: "#9c9c9c",
                },
                {
                  name: "Excess Cash",
                  y: pfNetDebtData[3],
                  color: "#ff0000",
                },
                {
                  name: "PF Net Debt", //pfNetDebtData[4]
                  isSum: true,
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + (Math.round(this.y! / 100) / 10) * -1 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
      }));

      const graph3Data: any[] = [];
      for (let i = 14; i < 28; i++) {
        graph3Data.push(Math.round(data.Outputs[i].Value / 100) / 10);
      }

      setState((prevState) => ({
        ...prevState,
        netDebtPFNetDebtChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "Net Debt & PF Net Debt (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Dec-20",
              "Mar-21",
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 30,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "Net Debt",
              data: [
                graph3Data[0],
                graph3Data[1],
                graph3Data[2],
                graph3Data[3],
                graph3Data[4],
                graph3Data[5],
                graph3Data[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return (
                      "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")"
                    );
                  } else {
                    return "$" + Highcharts.numberFormat(this.y!, 1);
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
            {
              type: "column",
              name: "PF Net Debt",
              data: [
                graph3Data[7],
                graph3Data[8],
                graph3Data[9],
                graph3Data[10],
                graph3Data[11],
                graph3Data[12],
                graph3Data[13],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return (
                      "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")"
                    );
                  } else {
                    return "$" + Highcharts.numberFormat(this.y!, 1);
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#48d1e1",
            },
          ],
        },
      }));

      const graph4Data: any[] = [];
      for (let i = 28; i < 42; i++) {
        graph4Data.push(Math.round(data.Outputs[i].Value / 100) / 10);
      }

      setState((prevState) => ({
        ...prevState,
        coreEBITDAAdjEBITDAChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "EBITDA & Credit EBITDA LTM Q2 2022 (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Dec-20",
              "Mar-21",
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 30,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "EBITDA",
              data: [
                graph4Data[0],
                graph4Data[1],
                graph4Data[2],
                graph4Data[3],
                graph4Data[4],
                graph4Data[5],
                graph4Data[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + this.y! * -1 + ")";
                  } else {
                    return "$" + this.y!;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
            {
              type: "column",
              name: "Credit EBITDA",
              data: [
                graph4Data[7],
                graph4Data[8],
                graph4Data[9],
                graph4Data[10],
                graph4Data[11],
                graph4Data[12],
                graph4Data[13],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + this.y! * -1 + ")";
                  } else {
                    return "$" + this.y!;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#48d1e1",
            },
          ],
        },
      }));

      const graph5Data: any[] = [];
      for (let i = 42; i < 63; i++) {
        graph5Data.push(Math.round(data.Outputs[i].Value * 100) / 100);
      }

      setState((prevState) => ({
        ...prevState,
        netDebtCoreAdjEBITDAAdjEBITDACovenantChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "LTM Leverage Ratios Summary",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Dec-20",
              "Mar-21",
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 50,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "Net Debt / EBITDA",
              data: [
                graph5Data[0],
                graph5Data[1],
                graph5Data[2],
                graph5Data[3],
                graph5Data[4],
                graph5Data[5],
                graph5Data[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return Math.round(this.y! * 10) / 10 + "x";
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
            {
              type: "column",
              name: "Net Debt / Credit EBITDA",
              data: [
                graph5Data[7],
                graph5Data[8],
                graph5Data[9],
                graph5Data[10],
                graph5Data[11],
                graph5Data[12],
                graph5Data[13],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return Math.round(this.y! * 10) / 10 + "x";
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#48d1e1",
            },
            {
              type: "line",
              name: "Net Debt / Credit EBITDA Covenant",
              data: [
                graph5Data[14],
                graph5Data[15],
                graph5Data[16],
                graph5Data[17],
                graph5Data[18],
                graph5Data[19],
                graph5Data[20],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return Math.round(this.y! * 10) / 10 + "x";
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#e83023",
            },
          ],
        },
      }));

      const graph6Data: any[] = [];
      for (let i = 63; i < 70; i++) {
        graph6Data.push(Math.round(data.Outputs[i].Value / 100) / 10);
      }

      setState((prevState) => ({
        ...prevState,
        covenantCushionChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "Credit EBITDA Cushion (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          legend: { enabled: false },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Dec-20",
              "Mar-21",
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 150,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "Covenant Cushion",
              data: [
                graph6Data[0],
                graph6Data[1],
                graph6Data[2],
                graph6Data[3],
                graph6Data[4],
                graph6Data[5],
                graph6Data[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return (
                      "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")"
                    );
                  } else {
                    return "$" + Highcharts.numberFormat(this.y!, 1);
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
          ],
        },
      }));

      const graph7data: any[] = [];

      for (let i = 70; i < 77; i++) {
        graph7data.push(Math.round(data.Outputs[i].Value / 100) / 10);
      }

      for (let i = 77; i < 84; i++) {
        graph7data.push(data.Outputs[i].Value); // the 0.25 shouldn't be rounded
      }

      for (let i = 84; i < 105; i++) {
        graph7data.push(Math.round(data.Outputs[i].Value / 100) / 10);
      }

      const graph8data: any[] = [];

      for (let i = 105; i < 245; i++) {
        //graph8data.push(Math.round((data.Outputs[i].Value) / 100) / 10);
        graph8data.push(data.Outputs[i].Value);
      }

      setState((prevState) => ({
        ...prevState,
        firstTableValues: graph7data,
        secondTableValues: graph8data,
        chartsLoadedFlag: true,
        errorFlag: false,
      }));
    } else if (xlsxProcessorQueryForTLMQ22022Result.isError) {
      console.log("FAILED to initialize parameters");
      console.log(xlsxProcessorQueryForTLMQ22022Result.error);
      setState({
        ...state,
        errorFlag: true,
        errorCode: "500",
        errorType: "Internal",
        errorMessage: "FAILED to initialize parameters",
      });
    }
  }, [xlsxProcessorQueryForTLMQ22022Result]);

  async function getValuesTLMQ22022() {
    xlsxProcessorQueryForTLMQ22022({
      DataSource: "Core.Celeri_Debt_EBITDA_Model_v14.1.xlsx",
      InputSheetName: null,
      Inputs: [],
      OutputSheetName: null,
      Outputs: [
        { SheetName: "HTML", RowIndex: 4, CellIndex: 4, Value: null }, //graph 1
        { SheetName: "HTML", RowIndex: 7, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 8, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 9, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 10, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 13, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 14, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 15, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 16, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 20, CellIndex: 4, Value: null }, //graph 2
        { SheetName: "HTML", RowIndex: 21, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 22, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 23, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 24, CellIndex: 4, Value: null },
        { SheetName: "HTML", RowIndex: 30, CellIndex: 9, Value: null }, //graph 3, Net Debt
        { SheetName: "HTML", RowIndex: 30, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 30, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 30, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 30, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 30, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 30, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 31, CellIndex: 9, Value: null }, //graph 3, PF Net Debt
        { SheetName: "HTML", RowIndex: 31, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 31, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 31, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 31, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 31, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 31, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 36, CellIndex: 9, Value: null }, //graph 4, EBITDA
        { SheetName: "HTML", RowIndex: 36, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 36, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 36, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 36, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 36, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 36, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 37, CellIndex: 9, Value: null }, //graph 4, Credit EBITDA
        { SheetName: "HTML", RowIndex: 37, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 37, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 37, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 37, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 37, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 37, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 42, CellIndex: 9, Value: null }, //graph 5, Net Debt / EBITDA
        { SheetName: "HTML", RowIndex: 42, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 42, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 42, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 42, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 42, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 42, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 43, CellIndex: 9, Value: null }, //graph 5, Net Debt / Credit EBITDA
        { SheetName: "HTML", RowIndex: 43, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 43, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 43, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 43, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 43, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 43, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 44, CellIndex: 9, Value: null }, //graph 5, Net Debt / Credit EBITDA Covenant
        { SheetName: "HTML", RowIndex: 44, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 44, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 44, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 44, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 44, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 44, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 50, CellIndex: 9, Value: null }, //graph 6, Covenant Cushion
        { SheetName: "HTML", RowIndex: 50, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 50, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 50, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 50, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 50, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 50, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 54, CellIndex: 9, Value: null }, //graph 7, EBITDA
        { SheetName: "HTML", RowIndex: 54, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 54, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 54, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 54, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 54, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 54, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 55, CellIndex: 9, Value: null }, //graph 7, x 25%
        { SheetName: "HTML", RowIndex: 55, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 55, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 55, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 55, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 55, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 55, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 56, CellIndex: 9, Value: null }, //graph 7, Adjustments Allowed
        { SheetName: "HTML", RowIndex: 56, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 56, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 56, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 56, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 56, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 56, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 57, CellIndex: 9, Value: null }, //graph 7, Adjustments Made
        { SheetName: "HTML", RowIndex: 57, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 57, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 57, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 57, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 57, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 57, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 58, CellIndex: 9, Value: null }, //graph 7, Adjustments Deficit
        { SheetName: "HTML", RowIndex: 58, CellIndex: 10, Value: null },
        { SheetName: "HTML", RowIndex: 58, CellIndex: 11, Value: null },
        { SheetName: "HTML", RowIndex: 58, CellIndex: 12, Value: null },
        { SheetName: "HTML", RowIndex: 58, CellIndex: 13, Value: null },
        { SheetName: "HTML", RowIndex: 58, CellIndex: 14, Value: null },
        { SheetName: "HTML", RowIndex: 58, CellIndex: 15, Value: null },
        { SheetName: "HTML", RowIndex: 67, CellIndex: 19, Value: null }, //graph 8, Net Income
        { SheetName: "HTML", RowIndex: 67, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 67, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 67, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 67, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 67, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 67, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 70, CellIndex: 19, Value: null }, //graph 8, Interest Expense
        { SheetName: "HTML", RowIndex: 70, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 70, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 70, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 70, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 70, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 70, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 71, CellIndex: 19, Value: null }, //graph 8, Dep and Amm
        { SheetName: "HTML", RowIndex: 71, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 71, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 71, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 71, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 71, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 71, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 72, CellIndex: 19, Value: null }, //graph 8, Tax
        { SheetName: "HTML", RowIndex: 72, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 72, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 72, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 72, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 72, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 72, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 73, CellIndex: 19, Value: null }, //graph 8, Core Adjustments
        { SheetName: "HTML", RowIndex: 73, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 73, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 73, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 73, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 73, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 73, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 76, CellIndex: 19, Value: null }, //graph 8, RR Synergies
        { SheetName: "HTML", RowIndex: 76, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 76, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 76, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 76, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 76, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 76, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 77, CellIndex: 19, Value: null }, //graph 8, Operating Investments
        { SheetName: "HTML", RowIndex: 77, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 77, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 77, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 77, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 77, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 77, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 78, CellIndex: 19, Value: null }, //graph 8, Other
        { SheetName: "HTML", RowIndex: 78, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 78, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 78, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 78, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 78, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 78, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 79, CellIndex: 19, Value: null }, //graph 8, Other Adjustments
        { SheetName: "HTML", RowIndex: 79, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 79, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 79, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 79, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 79, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 79, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 81, CellIndex: 19, Value: null }, //graph 8, Total Adjustments
        { SheetName: "HTML", RowIndex: 81, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 81, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 81, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 81, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 81, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 81, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 84, CellIndex: 19, Value: null }, //graph 8, EBITDA
        { SheetName: "HTML", RowIndex: 84, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 84, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 84, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 84, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 84, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 84, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 85, CellIndex: 19, Value: null }, //graph 8, Credit EBITDA
        { SheetName: "HTML", RowIndex: 85, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 85, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 85, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 85, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 85, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 85, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 88, CellIndex: 19, Value: null }, //graph 8, Net Debt/EBITDA
        { SheetName: "HTML", RowIndex: 88, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 88, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 88, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 88, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 88, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 88, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 89, CellIndex: 19, Value: null }, //graph 8, Net Debt/Credit EBITDA
        { SheetName: "HTML", RowIndex: 89, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 89, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 89, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 89, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 89, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 89, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 90, CellIndex: 19, Value: null }, //graph 8, Pro Forma Net Debt / EBITDA
        { SheetName: "HTML", RowIndex: 90, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 90, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 90, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 90, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 90, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 90, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 91, CellIndex: 19, Value: null }, //graph 8, Pro Forma Net Debt / Adj EBITDA
        { SheetName: "HTML", RowIndex: 91, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 91, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 91, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 91, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 91, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 91, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 94, CellIndex: 19, Value: null }, //graph 8, Net Debt / EBITDA Covenant
        { SheetName: "HTML", RowIndex: 94, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 94, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 94, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 94, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 94, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 94, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 95, CellIndex: 19, Value: null }, //graph 8, Net Debt
        { SheetName: "HTML", RowIndex: 95, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 95, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 95, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 95, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 95, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 95, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 96, CellIndex: 19, Value: null }, //graph 8, Adjustments Deficit / Excess
        { SheetName: "HTML", RowIndex: 96, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 96, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 96, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 96, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 96, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 96, CellIndex: 25, Value: null },
        { SheetName: "HTML", RowIndex: 97, CellIndex: 19, Value: null }, //graph 8, Covenant Cushion
        { SheetName: "HTML", RowIndex: 97, CellIndex: 20, Value: null },
        { SheetName: "HTML", RowIndex: 97, CellIndex: 21, Value: null },
        { SheetName: "HTML", RowIndex: 97, CellIndex: 22, Value: null },
        { SheetName: "HTML", RowIndex: 97, CellIndex: 23, Value: null },
        { SheetName: "HTML", RowIndex: 97, CellIndex: 24, Value: null },
        { SheetName: "HTML", RowIndex: 97, CellIndex: 25, Value: null },
      ],
    });
  }

  useEffect(() => {
    if (
      xlsxProcessorQueryForTLMQ42022Result.isSuccess &&
      xlsxProcessorQueryForTLMQ42022Result.status === "fulfilled"
    ) {
      const data = xlsxProcessorQueryForTLMQ42022Result.data;
      Highcharts.setOptions({
        lang: {
          decimalPoint: ".",
          thousandsSep: ",",
        },
      });
      // quarter format function

      Highcharts.dateFormats = {
        q: function (timestamp) {
          var date = new Date(timestamp);
          const quarter = Math.floor(date.getUTCMonth() / 3) + 1;
          return quarter.toString(10);
        },
      };

      HC_more(Highcharts);
      const EBITDALTMQ42022: any[] = [];
      for (let i = 0; i < 7; i++) {
        EBITDALTMQ42022.push(data.Outputs[i].Value); //waterfall values shouldn't be rounded here, messes with final sum and intermediate sum. Round up in formatter
      }

      setState((prevState) => ({
        ...prevState,
        EBITDALTMQ42022ChartOptions: {
          chart: {
            type: "waterfall",
          },

          tooltip: {
            enabled: false,
          },

          title: {
            text: "EBITDA LTM Q4 2022 (In Millions)",
            align: "left",
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 2,
                color: "#000000",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label;
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "EBITDA",
                  y: EBITDALTMQ42022[0],
                },
                {
                  name: "RR Synergies",
                  y: EBITDALTMQ42022[1],
                  color: "#ff0000",
                },
                {
                  name: "Oper. Inv.",
                  y: EBITDALTMQ42022[2],
                },
                {
                  name: "Insurance",
                  y: EBITDALTMQ42022[3],
                  color: "#ff0000",
                },
                {
                  name: "Settlement",
                  y: EBITDALTMQ42022[4],
                },
                {
                  name: "Other",
                  y: EBITDALTMQ42022[5],
                  color: "#ff0000",
                },
                {
                  name: "Credit EBITDA", //EBITDALTMQ42022[6]
                  isSum: true,
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + (Math.round(this.y! / 100) / 10) * -1 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
      }));

      const Q42022PFNetDebt: any = [];
      for (let i = 7; i < 13; i++) {
        Q42022PFNetDebt.push(data.Outputs[i].Value); //waterfall values shouldn't be rounded here, messes with final sum and intermediate sum. Round up in formatter
      }

      setState((prevState) => ({
        ...prevState,
        Q42022PFNetDebtChartOptions: {
          chart: {
            type: "waterfall",
          },

          title: {
            text: "Q4 2022 PF Net Debt (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },

          xAxis: {
            type: "category",
            lineWidth: 3,
            lineColor: "#000000",
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
          },

          yAxis: {
            visible: true,
            title: {
              text: "USD",
            },
            plotLines: [
              {
                value: 0,
                width: 1,
                color: "#aaa",
                zIndex: 10,
              },
            ],
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "($" + label.substring(1).slice(0, -1) + ")";
                } else {
                  var value: string;
                  if (label !== "0") {
                    value = "$" + label.slice(0, -1);
                  } else {
                    value = label;
                  }
                  return value;
                }
              },
            },
          },

          legend: {
            enabled: false,
          },

          series: [
            {
              type: "waterfall",
              upColor: "#4273E8",

              data: [
                {
                  name: "Debt",
                  y: Q42022PFNetDebt[0],
                },
                {
                  name: "Cash",
                  y: Q42022PFNetDebt[1],
                  color: "#ff0000",
                },
                {
                  name: "Net Debt",
                  isIntermediateSum: true, //Q42022PFNetDebt[2]
                  color: "#9c9c9c",
                },
                {
                  name: "Excess Cash",
                  y: Q42022PFNetDebt[3],
                  color: "#ff0000",
                },
                {
                  name: "PF Net Debt", //Q42022PFNetDebt[4]
                  isSum: true,
                  color: "#9c9c9c",
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + (Math.round(this.y! / 100) / 10) * -1 + ")";
                  } else {
                    return "$" + Math.round(this.y! / 100) / 10;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              pointPadding: 0,
            },
          ],
        },
      }));

      const LTMEBITDACreditEBITDA: any[] = [];
      for (let i = 13; i < 27; i++) {
        LTMEBITDACreditEBITDA.push(
          Math.round(data.Outputs[i].Value / 100) / 10,
        );
      }

      setState((prevState) => ({
        ...prevState,
        LTMEBITDACreditEBITDAChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "LTM EBITDA & Credit EBITDA (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
              "Sep-22",
              "Dec-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 30,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "EBITDA",
              data: [
                LTMEBITDACreditEBITDA[0],
                LTMEBITDACreditEBITDA[1],
                LTMEBITDACreditEBITDA[2],
                LTMEBITDACreditEBITDA[3],
                LTMEBITDACreditEBITDA[4],
                LTMEBITDACreditEBITDA[5],
                LTMEBITDACreditEBITDA[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return (
                      "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")"
                    );
                  } else {
                    return "$" + Highcharts.numberFormat(this.y!, 1);
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
            {
              type: "column",
              name: "Credit EBITDA",
              data: [
                LTMEBITDACreditEBITDA[7],
                LTMEBITDACreditEBITDA[8],
                LTMEBITDACreditEBITDA[9],
                LTMEBITDACreditEBITDA[10],
                LTMEBITDACreditEBITDA[11],
                LTMEBITDACreditEBITDA[12],
                LTMEBITDACreditEBITDA[13],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return (
                      "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")"
                    );
                  } else {
                    return "$" + Highcharts.numberFormat(this.y!, 1);
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#48d1e1",
            },
          ],
        },
      }));

      const NetDebtPFNetDebt: any[] = [];
      for (let i = 27; i < 41; i++) {
        NetDebtPFNetDebt.push(Math.round(data.Outputs[i].Value / 100) / 10);
      }

      setState((prevState) => ({
        ...prevState,
        Q4NetDebtPFNetDebtChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "Net Debt & PF Net Debt (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
              "Sep-22",
              "Dec-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 30,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "Net Debt",
              data: [
                NetDebtPFNetDebt[0],
                NetDebtPFNetDebt[1],
                NetDebtPFNetDebt[2],
                NetDebtPFNetDebt[3],
                NetDebtPFNetDebt[4],
                NetDebtPFNetDebt[5],
                NetDebtPFNetDebt[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + this.y! * -1 + ")";
                  } else {
                    return "$" + this.y!;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
            {
              type: "column",
              name: "PF Net Debt",
              data: [
                NetDebtPFNetDebt[7],
                NetDebtPFNetDebt[8],
                NetDebtPFNetDebt[9],
                NetDebtPFNetDebt[10],
                NetDebtPFNetDebt[11],
                NetDebtPFNetDebt[12],
                NetDebtPFNetDebt[13],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return "($" + this.y! * -1 + ")";
                  } else {
                    return "$" + this.y!;
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#48d1e1",
            },
          ],
        },
      }));

      const LTMLeverageRatioSummary: any[] = [];
      for (let i = 41; i < 62; i++) {
        LTMLeverageRatioSummary.push(
          Math.round(data.Outputs[i].Value * 10) / 10,
        );
      }

      setState((prevState) => ({
        ...prevState,
        LTMLeverageRatioSummaryChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "LTM Leverage Ratios Summary",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
              "Sep-22",
              "Dec-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 50,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "Net Debt / EBITDA",
              data: [
                LTMLeverageRatioSummary[0],
                LTMLeverageRatioSummary[1],
                LTMLeverageRatioSummary[2],
                LTMLeverageRatioSummary[3],
                LTMLeverageRatioSummary[4],
                LTMLeverageRatioSummary[5],
                LTMLeverageRatioSummary[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return Math.round(this.y! * 10) / 10 + "x";
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
            {
              type: "column",
              name: "Net Debt / Credit EBITDA",
              data: [
                LTMLeverageRatioSummary[7],
                LTMLeverageRatioSummary[8],
                LTMLeverageRatioSummary[9],
                LTMLeverageRatioSummary[10],
                LTMLeverageRatioSummary[11],
                LTMLeverageRatioSummary[12],
                LTMLeverageRatioSummary[13],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return Math.round(this.y! * 10) / 10 + "x";
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#48d1e1",
            },
            {
              type: "line",
              name: "Net Debt / Credit EBITDA Covenant",
              data: [
                LTMLeverageRatioSummary[14],
                LTMLeverageRatioSummary[15],
                LTMLeverageRatioSummary[16],
                LTMLeverageRatioSummary[17],
                LTMLeverageRatioSummary[18],
                LTMLeverageRatioSummary[19],
                LTMLeverageRatioSummary[20],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return Math.round(this.y! * 10) / 10 + "x";
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#e83023",
            },
          ],
        },
      }));

      const LTMCreditEBITDACushion: any[] = [];
      for (let i = 62; i < 69; i++) {
        LTMCreditEBITDACushion.push(
          Math.round(data.Outputs[i].Value / 100) / 10,
        );
      }

      setState((prevState) => ({
        ...prevState,
        LTMCreditEBITDACushionChartOptions: {
          chart: {
            type: "column",
          },
          title: {
            text: "LTM Credit EBITDA Cushion (In Millions)",
            align: "left",
          },

          tooltip: {
            enabled: false,
          },
          legend: { enabled: false },
          xAxis: {
            lineWidth: 3,
            lineColor: "#000000",
            categories: [
              "Jun-21",
              "Sep-21",
              "Dec-21",
              "Mar-22",
              "Jun-22",
              "Sep-22",
              "Dec-22",
            ],
            labels: {
              style: {
                fontSize: "11",
                fontWeight: "bold",
              },
            },
            crosshair: true,
          },
          yAxis: {
            visible: false,
            title: {
              useHTML: true,
              text: "",
            },
            labels: {
              formatter: function () {
                var label: string = this.axis.defaultLabelFormatter.call(this);
                if (/-/.test(label)) {
                  return "(" + label.substring(1) + ")";
                } else {
                  return label;
                }
              },
            },
          },
          plotOptions: {
            column: {
              pointWidth: 150,
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: [
            {
              type: "column",
              name: "Covenant Cushion",
              data: [
                LTMCreditEBITDACushion[0],
                LTMCreditEBITDACushion[1],
                LTMCreditEBITDACushion[2],
                LTMCreditEBITDACushion[3],
                LTMCreditEBITDACushion[4],
                LTMCreditEBITDACushion[5],
                LTMCreditEBITDACushion[6],
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  if (this.y! < 0) {
                    return (
                      "($" + Highcharts.numberFormat(this.y! * -1, 1) + ")"
                    );
                  } else {
                    return "$" + Highcharts.numberFormat(this.y!, 1);
                  }
                },
                style: {
                  fontWeight: "bold",
                },
              },
              color: "#2d76ef",
            },
          ],
        },
      }));

      setState((prevState) => ({
        ...prevState,
        Q4ChartsLoadedFlag: true,
        exceeded25Flag: data.Outputs[69].Value,
        errorFlag: false,
      }));
    } else if (xlsxProcessorQueryForTLMQ42022Result.isError) {
      console.log("FAILED to initialize parameters");
      console.log(xlsxProcessorQueryForTLMQ42022Result.error);
      setState({
        ...state,
        errorFlag: true,
        errorCode: "500",
        errorType: "Internal",
        errorMessage: "FAILED to initialize parameters",
      });
    }
  }, [xlsxProcessorQueryForTLMQ42022Result]);

  function getValuesTLMQ42022() {
    xlsxProcessorQueryForTLMQ42022({
      DataSource:
        "https://docs.google.com/spreadsheets/d/11tGVBAlviN0zg-p2-WyMIRoFMe4oClcK/",
      InputSheetName: null,
      Inputs: [
        {
          SheetName: "Inputs",
          RowIndex: 8,
          CellIndex: 3,
          Value: state.inputValues[0],
        },
        {
          SheetName: "Inputs",
          RowIndex: 9,
          CellIndex: 3,
          Value: state.inputValues[1],
        },
        {
          SheetName: "Inputs",
          RowIndex: 10,
          CellIndex: 3,
          Value: state.inputValues[2],
        },
        {
          SheetName: "Inputs",
          RowIndex: 11,
          CellIndex: 3,
          Value: state.inputValues[3],
        },
        {
          SheetName: "Inputs",
          RowIndex: 12,
          CellIndex: 3,
          Value: state.inputValues[4],
        },
      ],
      OutputSheetName: null,
      Outputs: [
        { SheetName: "Html-Debt Cov", RowIndex: 5, CellIndex: 2, Value: null }, //graph 1 EBITDA LTM Q4 2022
        { SheetName: "Html-Debt Cov", RowIndex: 6, CellIndex: 2, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 7, CellIndex: 2, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 8, CellIndex: 2, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 9, CellIndex: 2, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 10, CellIndex: 2, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 11, CellIndex: 2, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 17, CellIndex: 6, Value: null }, //graph 2 Q4 2022 PF Net Debt
        { SheetName: "Html-Debt Cov", RowIndex: 18, CellIndex: 6, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 19, CellIndex: 6, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 20, CellIndex: 6, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 21, CellIndex: 6, Value: null },
        { SheetName: "Html-Debt Cov", RowIndex: 22, CellIndex: 6, Value: null },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 10,
          Value: null,
        }, //graph 3, LTM EBITDA & Credit EBITDA
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 11,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 12,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 13,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 14,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 15,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 27,
          CellIndex: 16,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 10,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 11,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 12,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 13,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 14,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 15,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 28,
          CellIndex: 16,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 20,
          Value: null,
        }, //graph 4, Net Debt & PF Net Debt
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 21,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 22,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 23,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 24,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 25,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 35,
          CellIndex: 26,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 20,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 21,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 22,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 23,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 24,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 25,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 36,
          CellIndex: 26,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 30,
          Value: null,
        }, //graph 5, LTM Leverage Ratio Summary
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 31,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 32,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 33,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 34,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 35,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 44,
          CellIndex: 36,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 30,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 31,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 32,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 33,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 34,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 35,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 45,
          CellIndex: 36,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 30,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 31,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 32,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 33,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 34,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 35,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 46,
          CellIndex: 36,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 40,
          Value: null,
        }, //graph 6, LTM Credit EBITDA Cushion
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 41,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 42,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 43,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 44,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 45,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 51,
          CellIndex: 46,
          Value: null,
        },
        {
          SheetName: "Html-Debt Cov",
          RowIndex: 58,
          CellIndex: 49,
          Value: null,
        }, //error check
      ],
    });
  }

  const handleChange = (newValue: number[]) => {
    setState({ ...state, inputValues: newValue });
  };

  return (
    <div className="module-master">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="module-title">Debt Covenant</div>
        <div style={{ display: "flex", padding: 20 }} data-toggle="buttons">
          <div>LTM 4Q 2022</div>
          <div>
            <Switch
              onChange={(e) => setState({ ...state, Q4Mode: !state.Q4Mode })}
              checked={state.Q4Mode}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
        </div>
      </div>

      <div>
        {!(state.chartsLoadedFlag && state.initialDataLoaded) && (
          <div className="loading-div">
            <FontAwesomeIcon
              icon={faSpinner}
              size="2x"
              spin={true}
              style={{ margin: "0 auto" }}
            />
          </div>
        )}
        {state.errorFlag && (
          <Error
            code={state.errorCode}
            type={state.errorType}
            message={state.errorMessage}
          />
        )}
        {state.chartsLoadedFlag &&
          state.initialDataLoaded &&
          !state.errorFlag && (
            <div style={{ width: "100%", height: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: !state.Q4Mode ? "block" : "none",
                }}
              >
                <div className="module-row">
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.netIncomeChartOptions}
                      containerProps={{ style: { width: "100%" } }}
                    />
                  </div>
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.pfNetDebtChartOptions}
                      containerProps={{ style: { width: "100%" } }}
                    />
                  </div>
                </div>
                <div className="module-row">
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.coreEBITDAAdjEBITDAChartOptions}
                      containerProps={{ style: { width: "100%" } }}
                    />
                  </div>
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.netDebtPFNetDebtChartOptions}
                      containerProps={{ style: { width: "100%" } }}
                    />
                  </div>
                </div>
                <div className="module-chart-container">
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={
                      state.netDebtCoreAdjEBITDAAdjEBITDACovenantChartOptions
                    }
                    containerProps={{ style: { width: "100%" } }}
                  />
                </div>
                <div className="module-chart-container">
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={state.covenantCushionChartOptions}
                    containerProps={{ style: { width: "100%" } }}
                  />
                </div>
                <div className="module-table-1">
                  <div className="module-table-1-title">
                    Last Twelve Month Figures Calculations
                  </div>
                  <div className="module-table-1-contents">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="module-table-1-columns">Dec-20</th>
                          <th className="module-table-1-columns">Mar-21</th>
                          <th className="module-table-1-columns">Jun-21</th>
                          <th className="module-table-1-columns">Sep-21</th>
                          <th className="module-table-1-columns">Dec-21</th>
                          <th className="module-table-1-columns">Mar-22</th>
                          <th className="module-table-1-columns">Jun-22</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="module-table-1-sub-title-big">
                            EBITDA Calculation
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">Net Income</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[0],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[1],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[2],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[3],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[4],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[5],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[6],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-sub-title-small"
                            scope="row"
                          >
                            Core Adjustments:
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">Interest Expense</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[7],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[8],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[9],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[10],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[11],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[12],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[13],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Dep. And Ammort.</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[14],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[15],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[16],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[17],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[18],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[19],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[20],
                              "sum",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Tax</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[21],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[22],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[23],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[24],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[25],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[26],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[27],
                              "sum",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-subtotal" scope="row">
                            Core Adjustments (A)
                          </th>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[28],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[29],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[30],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[31],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[32],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[33],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[34],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-sub-title-small"
                            scope="row"
                          >
                            Other Adjustments:
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">RR Synergies</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[35],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[36],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[37],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[38],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[39],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[40],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[41],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Operating Investments</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[42],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[43],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[44],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[45],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[46],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[47],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[48],
                              "sum",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Other</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[49],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[50],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[51],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[52],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[53],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[54],
                              "sum",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[55],
                              "sum",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-subtotal" scope="row">
                            Other Adjustments (B)
                          </th>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[56],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[57],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[58],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[59],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[60],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[61],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-subtotal">
                            {tableStringFormatTable(
                              state.secondTableValues[62],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-total" scope="row">
                            Total Adjustments (A + B)
                          </th>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[63],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[64],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[65],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[66],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[67],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[68],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[69],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-sub-title-big">
                            EBITDA
                          </th>
                        </tr>
                        <tr>
                          <th className="module-table-1-ebitda-top" scope="row">
                            EBITDA (Net Income + A)
                          </th>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[70],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[71],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[72],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[73],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[74],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[75],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-top">
                            {tableStringFormatTable(
                              state.secondTableValues[76],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-ebitda-bottom"
                            scope="row"
                          >
                            Credit EBITDA (Net Income + A + B)
                          </th>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[77],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[78],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[79],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[80],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[81],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[82],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-ebitda-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[83],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-sub-title-big">
                            Debt / EBITA Ratios
                          </th>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-ebita-titles-top"
                            scope="row"
                          >
                            Net Debt / EBITDA
                          </th>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[84],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[85],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[86],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[87],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[88],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[89],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-top">
                            {tableStringFormatTable(
                              state.secondTableValues[90],
                              "ratio",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-ebita-titles"
                            scope="row"
                          >
                            Net Debt / Credit EBITDA
                          </th>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[91],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[92],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[93],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[94],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[95],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[96],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[97],
                              "ratio",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-ebita-titles"
                            scope="row"
                          >
                            Pro Forma Net Debt / EBITDA
                          </th>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[98],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[99],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[100],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[101],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[102],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[103],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-middle">
                            {tableStringFormatTable(
                              state.secondTableValues[104],
                              "ratio",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            className="module-table-1-ebita-titles-bottom"
                            scope="row"
                          >
                            Pro Forma Net Debt / Credit EBITDA
                          </th>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[105],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[106],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[107],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[108],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[109],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[110],
                              "ratio",
                            )}
                          </td>
                          <td className="module-table-1-ebita-bottom">
                            {tableStringFormatTable(
                              state.secondTableValues[111],
                              "ratio",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-sub-title-big">Memo</th>
                        </tr>
                        <tr>
                          <th scope="row">Net Debt / EBITDA Covenant</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[112],
                              "ratio",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[113],
                              "ratio",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[114],
                              "ratio",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[115],
                              "ratio",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[116],
                              "ratio",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[117],
                              "ratio",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[118],
                              "ratio",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Net Debt</th>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[119],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[120],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[121],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[122],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[123],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[124],
                              "subtotal",
                            )}
                          </td>
                          <td>
                            {tableStringFormatTable(
                              state.secondTableValues[125],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="module-table-1-total" scope="row">
                            Covenant "Cushion"
                          </th>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[133],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[134],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[135],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[136],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[137],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[138],
                              "subtotal",
                            )}
                          </td>
                          <td className="module-table-1-total">
                            {tableStringFormatTable(
                              state.secondTableValues[139],
                              "subtotal",
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              <div style={{ display: state.Q4Mode ? "block" : "none" }}>
                <div className="module-input-sliders">
                  <ResultFromMultipleInputSliders
                    data={[
                      {
                        startingValue: state.initialInputValues[0],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Insurance Payment",
                      },
                      {
                        startingValue: state.initialInputValues[1],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Settlement",
                      },
                      {
                        startingValue: state.initialInputValues[2],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Run-Rate synergies ",
                      },
                      {
                        startingValue: state.initialInputValues[3],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Operating Investment",
                      },
                      {
                        startingValue: state.initialInputValues[4],
                        firstSymbol: "$",
                        secondSymbol: "K",
                        label: "Other EBITDA Adj.",
                      },
                    ]}
                    resultResolver={(values) => 0}
                    showLabels={true}
                    result={{
                      firstSymbol: "",
                      secondSymbol: "",
                      label: "",
                    }}
                    showResult={false}
                    onNewValuesChange={(values) => handleChange(values)}
                    columnlabel={""}
                    vertical={false}
                  />
                  <div
                    style={{ display: "flex", padding: 20 }}
                    data-toggle="buttons"
                  >
                    <button
                      className="btn btn-primary"
                      onClick={getValuesTLMQ42022}
                    >
                      Run Model
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "larger",
                    textAlign: "center",
                    display: state.exceeded25Flag > 0 ? "block" : "none",
                  }}
                >
                  Error: The sum of RR Synergies and Operating Investment
                  exceeds 25% Cap by {state.exceeded25Flag}
                </div>
                <div
                  style={{
                    width: "100%",
                    display:
                      state.Q4ChartsLoadedFlag && !(state.exceeded25Flag > 0)
                        ? "block"
                        : "none",
                  }}
                >
                  <div className="module-row">
                    <div className="module-chart-container">
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"chart"}
                        options={state.EBITDALTMQ42022ChartOptions}
                        containerProps={{ style: { width: "100%" } }}
                      />
                    </div>
                    <div className="module-chart-container">
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"chart"}
                        options={state.Q42022PFNetDebtChartOptions}
                        containerProps={{ style: { width: "100%" } }}
                      />
                    </div>
                  </div>
                  <div className="module-row">
                    <div className="module-chart-container">
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"chart"}
                        options={state.LTMEBITDACreditEBITDAChartOptions}
                        containerProps={{ style: { width: "100%" } }}
                      />
                    </div>
                    <div className="module-chart-container">
                      <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={"chart"}
                        options={state.Q4NetDebtPFNetDebtChartOptions}
                        containerProps={{ style: { width: "100%" } }}
                      />
                    </div>
                  </div>
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.LTMLeverageRatioSummaryChartOptions}
                      containerProps={{ style: { width: "100%" } }}
                    />
                  </div>
                  <div className="module-chart-container">
                    <HighchartsReact
                      highcharts={Highcharts}
                      constructorType={"chart"}
                      options={state.LTMCreditEBITDACushionChartOptions}
                      containerProps={{ style: { width: "100%" } }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
