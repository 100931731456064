import { useState } from "react";
import { Product } from "../services/types/gallus";
import { useGenerateProductPaymentUrlQuery } from "../services/gallus";
import { useAppSelector } from "../app/hooks";
import { selectUserAuthContext } from "../features/console/consoleSlice";

interface SubscribeProductCardProps {
  product: Product;
  backUrl?: string;
}

export const SubscribeProductCard = (props: SubscribeProductCardProps) => {
  const userAuthContext = useAppSelector(selectUserAuthContext);

  const [subscribeError, setSubscribeError] = useState<boolean>(false);
  const [isGeneratingPaymentUrl, setIsGeneratingPaymentUrl] =
    useState<boolean>(false);
  const paymentURL = useGenerateProductPaymentUrlQuery({
    cognitoSubject: userAuthContext!.idTokenPayload["sub"],
    productId: props.product.ID.toString(),
    backUrl: props.backUrl ?? window.location.href,
  });

  const subscribe = async () => {
    try {
      setIsGeneratingPaymentUrl(true);
      if (paymentURL.isSuccess && paymentURL.status === "fulfilled") {
        window.location.assign(paymentURL.data);
      } else {
        setSubscribeError(true);
        setIsGeneratingPaymentUrl(false);
      }
    } catch (e) {
      console.error(e);
      setSubscribeError(true);
      setIsGeneratingPaymentUrl(false);
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="lp-pill">
        <div>
          <div className="lp-pill-name">{props.product.Name}</div>
          <div className="lp-pill-description">{props.product.Description}</div>
        </div>
        <div>
          {!subscribeError &&
            (isGeneratingPaymentUrl ? (
              <button
                className="btn btn-primary lp-pill-button"
                disabled={true}
              >
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary lp-pill-button"
                onClick={(e) => {
                  subscribe();
                }}
              >
                Subscribe
              </button>
            ))}
          {subscribeError && (
            <button className="btn btn-primary lp-pill-button" disabled={true}>
              Subscription error, please contact us
            </button>
          )}
        </div>
        <div
          className="lp-pill-background"
          style={{ backgroundImage: `url(${props.product?.IconURL})` }}
        ></div>
      </div>
    </div>
  );
};
