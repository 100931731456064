/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "ag-grid-community/styles/ag-grid.css";
import { OptionsMenu } from "../../../features/OptionsMenu";
import { agGridObject } from "../../../features/ExportToCSV";
import { Error, tryAgainLaterMessage } from "../../../components/Error";
import { DateSelector } from "../../../features/dateSelector";
import "react-day-picker/dist/style.css";
import {
  useLazyGI_GRate_DateCompare_ViewQuery,
  useLazyGI_GRate_LocksValuesDay_ViewQuery,
  useLazyGI_GRate_Summary_ViewQuery,
  useLazyGI_GRate_Summary_ViewTable2Query,
  useLazyGI_GRate_Summary_ViewTable3Query,
} from "../../../services/gallus";
import { TSToAgGrid } from "../../../features/TSToAgGrid";
import { AgGridReact } from "ag-grid-react";
import { ColumnResizedEvent } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import { tsDataResultsResponse } from "../../../services/types/gallus";

interface ForecastVarianceProps {
  overrideTitle?: string;
}

export const ForecastVariance = (props: ForecastVarianceProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [csvData, setCSVData] = React.useState<agGridObject<string>[]>([]);

  const [errorFlag, setErrorFlag] = React.useState<boolean>(false);
  const [errorCode, setErrorCode] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [errorType, setErrorType] = React.useState<string>("");

  const [tableArray, setTableArray] = React.useState<JSX.Element[]>([]);
  const [datesAsString, setDatesAsString] = React.useState<string[]>([]);

  const [
    GI_GRate_DateCompare_ViewQuery,
    GI_GRate_DateCompare_ViewQueryResults,
  ] = useLazyGI_GRate_DateCompare_ViewQuery();
  const [
    GI_GRate_LocksValuesDay_ViewQuery,
    GI_GRate_LocksValuesDay_ViewQueryResults,
  ] = useLazyGI_GRate_LocksValuesDay_ViewQuery();
  const [GI_GRate_Summary_ViewQuery, GI_GRate_Summary_ViewQueryResults] =
    useLazyGI_GRate_Summary_ViewQuery();
  const [
    GI_GRate_Summary_ViewTable2Query,
    GI_GRate_Summary_ViewTable2QueryResults,
  ] = useLazyGI_GRate_Summary_ViewTable2Query();
  const [
    GI_GRate_Summary_ViewTable3Query,
    GI_GRate_Summary_ViewTable3QueryResults,
  ] = useLazyGI_GRate_Summary_ViewTable3Query();

  const onGridReady = (params: ColumnResizedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const renderTable = (data: tsDataResultsResponse) => {
    var rowsWithFormat: any[] = [];
    var rowsWithoutFormat: any[] = [];
    var result;

    result = TSToAgGrid(data.results, false);

    result.rows.forEach((element) => {
      rowsWithFormat.push(element.formatted);
    });

    result.rows.forEach((element) => {
      rowsWithoutFormat.push(element.notFormatted);
    });

    let tmpcsv = csvData;
    let newData = {
      title: "ForecastVariance",
      columnDefs: result.columns,
      rowDefs: rowsWithoutFormat,
    };
    if (!csvData.find((e) => e === newData)) {
      tmpcsv.push(newData);
    }
    setCSVData(tmpcsv);
    console.log("Generated CSV");

    return (
      <div>
        <div className="grid-title">{result.title}</div>
        <AgGridReact
          domLayout={"autoHeight"}
          defaultColDef={{
            cellClass: "grate-normal-text",
          }}
          rowClassRules={result.rowStyles}
          rowData={rowsWithFormat}
          columnDefs={result.columnsWithGrouping}
          onGridReady={onGridReady}
          containerStyle={{ paddingLeft: "15px", paddingBottom: "15px" }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (
      GI_GRate_DateCompare_ViewQueryResults.isSuccess &&
      GI_GRate_DateCompare_ViewQueryResults.status === "fulfilled" &&
      GI_GRate_LocksValuesDay_ViewQueryResults.isSuccess &&
      GI_GRate_LocksValuesDay_ViewQueryResults.status === "fulfilled" &&
      GI_GRate_Summary_ViewQueryResults.isSuccess &&
      GI_GRate_Summary_ViewQueryResults.status === "fulfilled" &&
      GI_GRate_Summary_ViewTable2QueryResults.isSuccess &&
      GI_GRate_Summary_ViewTable2QueryResults.status === "fulfilled" &&
      GI_GRate_Summary_ViewTable3QueryResults.isSuccess &&
      GI_GRate_Summary_ViewTable3QueryResults.status === "fulfilled"
    ) {
      var table1: JSX.Element = <></>;
      var table2: JSX.Element = <></>;
      var table3: JSX.Element = <></>;
      var table4: JSX.Element = <></>;
      var table5: JSX.Element = <></>;

      if (GI_GRate_DateCompare_ViewQueryResults.data.resultsstatus) {
        setTimeout(() => {
          GI_GRate_DateCompare_ViewQuery({ dateArray: datesAsString });
        }, 30000);
      } else {
        table1 = renderTable(GI_GRate_DateCompare_ViewQueryResults.data);
      }

      if (GI_GRate_LocksValuesDay_ViewQueryResults.data.resultsstatus) {
        setTimeout(() => {
          GI_GRate_LocksValuesDay_ViewQuery({ dateArray: datesAsString });
        }, 30000);
      } else {
        table2 = renderTable(GI_GRate_LocksValuesDay_ViewQueryResults.data);
      }

      if (GI_GRate_Summary_ViewQueryResults.data.resultsstatus) {
        setTimeout(() => {
          GI_GRate_Summary_ViewQuery({ dateArray: datesAsString });
        }, 30000);
      } else {
        table3 = renderTable(GI_GRate_Summary_ViewQueryResults.data);
      }

      if (GI_GRate_Summary_ViewTable2QueryResults.data.resultsstatus) {
        setTimeout(() => {
          GI_GRate_Summary_ViewTable2Query({ dateArray: datesAsString });
        }, 30000);
      } else {
        table4 = renderTable(GI_GRate_Summary_ViewTable2QueryResults.data);
      }

      if (GI_GRate_Summary_ViewTable3QueryResults.data.resultsstatus) {
        setTimeout(() => {
          GI_GRate_Summary_ViewTable3Query({ dateArray: datesAsString });
        }, 30000);
      } else {
        table5 = renderTable(GI_GRate_Summary_ViewTable3QueryResults.data);
      }

      if (
        !(
          GI_GRate_DateCompare_ViewQueryResults.data.resultsstatus ||
          GI_GRate_LocksValuesDay_ViewQueryResults.data.resultsstatus ||
          GI_GRate_Summary_ViewQueryResults.data.resultsstatus ||
          GI_GRate_Summary_ViewTable2QueryResults.data.resultsstatus ||
          GI_GRate_Summary_ViewTable3QueryResults.data.resultsstatus
        )
      ) {
        setTableArray([table1, table2, table3, table4, table5]);
        setErrorFlag(false);
        setLoading(false);
      }
    } else if (
      GI_GRate_DateCompare_ViewQueryResults.isError ||
      GI_GRate_LocksValuesDay_ViewQueryResults.isError ||
      GI_GRate_Summary_ViewQueryResults.isError ||
      GI_GRate_Summary_ViewTable2QueryResults.isError ||
      GI_GRate_Summary_ViewTable3QueryResults.isError
    ) {
      setErrorFlag(true);
      setLoading(false);
      setErrorCode("500");
      setErrorMessage(tryAgainLaterMessage);
      setErrorType("InternalAPI");
    }
  }, [
    GI_GRate_DateCompare_ViewQueryResults,
    GI_GRate_LocksValuesDay_ViewQueryResults,
    GI_GRate_Summary_ViewQueryResults,
    GI_GRate_Summary_ViewTable2QueryResults,
    GI_GRate_Summary_ViewTable3QueryResults,
  ]);

  return (
    <div className="module-master" style={{ padding: "10px 10px 0 20px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className="module-title">Forecast Variance</div>
      </div>
      <div>
        {errorFlag && (
          <div>
            <Error code={errorCode} type={errorType} message={errorMessage} />
          </div>
        )}
        {!errorFlag && (
          <div>
            <OptionsMenu
              exportToCSV={{
                filename: `${typeof props.overrideTitle !== "undefined" ? props.overrideTitle : "ForecastVariance"}`,
                CSVData: csvData,
              }}
            />
            <div style={{ display: "flex" }}>
              <DateSelector
                buttonObject={{
                  text: "Compare",
                  functionality(selectedDates) {
                    if (typeof selectedDates !== "undefined") {
                      let datesAsStringTMP: string[] = [];
                      selectedDates.forEach((date) => {
                        let year = date.getFullYear();
                        let month = (date.getMonth() + 1).toString();
                        let day = date.getDate().toString();
                        if (date.getDate() <= 9) {
                          day = "0" + date.getDate();
                        }
                        if (date.getMonth() + 1 <= 9) {
                          month = "0" + (date.getMonth() + 1);
                        }
                        datesAsStringTMP.push(year + "-" + month + "-" + day);
                      });
                      setCSVData([]);
                      setDatesAsString(datesAsStringTMP);
                      GI_GRate_DateCompare_ViewQuery({
                        dateArray: datesAsStringTMP,
                      });
                      GI_GRate_LocksValuesDay_ViewQuery({
                        dateArray: datesAsStringTMP,
                      });
                      GI_GRate_Summary_ViewQuery({
                        dateArray: datesAsStringTMP,
                      });
                      GI_GRate_Summary_ViewTable2Query({
                        dateArray: datesAsStringTMP,
                      });
                      GI_GRate_Summary_ViewTable3Query({
                        dateArray: datesAsStringTMP,
                      });
                      setLoading(true);
                    }
                  },
                }}
              />
              {loading && (
                <div className="loading-div">
                  <FontAwesomeIcon
                    icon={faSpinner}
                    size="2x"
                    spin={true}
                    style={{ margin: "0 auto" }}
                  />
                  Building tables, please wait.
                </div>
              )}
              {!loading && (
                <div style={{ height: "100%", width: "100%" }}>
                  {tableArray}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
