/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
//import axios from "axios";
import "./BalanceSheet.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import { ColumnResizedEvent } from "ag-grid-community";
//import { environmentConfig } from "../../../environment";
import {
  useTrialBalance_GetFiscalYearFiscalQuarterQuery,
  useBalanceSheet_GetQuery,
} from "../../../services/gallus";

/* const client = axios.create({
  baseURL: environmentConfig.backendApiEndpoint,
}); */

interface BalanceSheetProps {}

interface BalanceSheetState {
  columns: gridColumnAccountType[];
  columnsMemo: gridColumnAccountType[];
  data: gridRowType[];
  memo: gridRowType[];
  loadedColumns: boolean;
  loadedData: boolean;
}

interface accountQueryType {
  Name: string;
  Items: accountCellType[];
}

interface accountCellType {
  FiscalYear: string;
  FiscalMonth: string;
  Value: string;
}

interface gridColumnAccountType {
  field: string;
}

interface gridRowType {
  [key: string]: string;
}

export const BalanceSheet = (props: BalanceSheetProps) => {
  const [state, setState] = useState<BalanceSheetState>({
    columns: [],
    columnsMemo: [],
    data: [],
    memo: [],
    loadedColumns: false,
    loadedData: false,
  });

  const TrialBalance_GetFiscalYearFiscalQuarterResult =
    useTrialBalance_GetFiscalYearFiscalQuarterQuery();
  const BalanceSheet_GetResult = useBalanceSheet_GetQuery();

  const getColumns = (response: any) => {
    const gridColumns: gridColumnAccountType[] = [{ field: "Account" }];
    const gridColumnsMemo: gridColumnAccountType[] = [{ field: "Memo Items" }];
    for (let i = 0; i < response.data.results.length; i++) {
      if (
        !(response.data.results[i].FiscalYear === "2019") &&
        !(
          response.data.results[i].FiscalYear === "2020" &&
          !(response.data.results[i].FiscalQuarter === "Q4")
        ) &&
        !(
          response.data.results[i].FiscalYear === "2022" &&
          response.data.results[i].FiscalQuarter === "Q3"
        )
      ) {
        gridColumns.push({
          field:
            response.data.results[i].FiscalQuarter +
            "-" +
            response.data.results[i].FiscalYear.slice(2),
        });
        gridColumnsMemo.push({
          field:
            response.data.results[i].FiscalQuarter +
            "-" +
            response.data.results[i].FiscalYear.slice(2),
        });
      }
    }

    return {
      columns: gridColumns,
      columnsMemo: gridColumnsMemo,
      loadedColumns: true,
    };
  };

  const getData = (response: any) => {
    let processedData: gridRowType[] = []; //the arrays that will be assigned to the state
    let processedMemo: gridRowType[] = [];
    let memoItemsNames: string[] = [
      "Working Capital",
      "Current Ratio",
      "Quick Ratio",
      "Leverage (Assets/Equity)",
    ];

    response.data.results.forEach((element: accountQueryType) => {
      let processedValue: gridRowType = {}; //a row
      element.Items.forEach((cell: accountCellType) => {
        if (
          !(cell.FiscalYear === "2019") &&
          !(cell.FiscalYear === "2020" && !(cell.FiscalMonth === "12")) &&
          !(cell.FiscalYear === "2022" && cell.FiscalMonth === "9") &&
          !(cell.FiscalYear === "2022" && cell.FiscalMonth === "12")
        ) {
          let columnName: string = formatDates(
            cell.FiscalMonth,
            cell.FiscalYear,
          );
          if (memoItemsNames.includes(element.Name)) {
            processedValue["Memo Items"] = element.Name;
            if (element.Name === "Working Capital") {
              processedValue[columnName] = memoNumberFormat(
                cell.Value,
                "money",
              );
            } else {
              processedValue[columnName] = memoNumberFormat(
                cell.Value,
                "ratio",
              );
            }
          } else {
            processedValue["Account"] = element.Name;
            processedValue[columnName] = tableNumberFormat(cell.Value);
          }
        }
      });

      if (memoItemsNames.includes(element.Name)) {
        processedMemo.push(processedValue);
      } else {
        processedData.push(processedValue);
      }
    });
    return { data: processedData, memo: processedMemo, loadedData: true };
  };

  const formatDates = (month: string, year: string): string => {
    let result: string = "";
    switch (month) {
      case "3":
        result = "Q1-" + year.slice(2);
        break;
      case "6":
        result = "Q2-" + year.slice(2);
        break;
      case "9":
        result = "Q3-" + year.slice(2);
        break;
      case "12":
        result = "Q4-" + year.slice(2);
        break;
    }
    return result;
  };

  useEffect(() => {
    if (
      TrialBalance_GetFiscalYearFiscalQuarterResult.isSuccess &&
      TrialBalance_GetFiscalYearFiscalQuarterResult.status === "fulfilled" &&
      BalanceSheet_GetResult.isSuccess &&
      BalanceSheet_GetResult.status === "fulfilled"
    ) {
      const [columns, data] = [
        getColumns(TrialBalance_GetFiscalYearFiscalQuarterResult),
        getData(BalanceSheet_GetResult),
      ];

      setState({ ...state, ...columns, ...data });
    }
  }, [TrialBalance_GetFiscalYearFiscalQuarterResult, BalanceSheet_GetResult]);

  useEffect(() => {
    if (state.columns.length > 0 && state.data.length > 0) {
      setState({ ...state, loadedColumns: true, loadedData: true });
    }
  }, [state.columns, state.data]);

  const tableNumberFormat = (data: string) => {
    let result;
    let dataNumber: number = parseFloat(data);

    let processedData: number = Math.round(dataNumber);
    if (processedData < 0) {
      result =
        "(" +
        (-1 * processedData).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        ")";
    } else {
      if (processedData === 0) {
        result = "-";
      } else {
        result = processedData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    return result;
  };

  const memoNumberFormat = (value: string, type: string) => {
    let result: string = "";
    switch (type) {
      case "number":
        if (parseFloat(value) < 0) {
          result =
            "(" +
            Math.round(-1 * parseFloat(value))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            ")";
        } else {
          result = Math.round(parseFloat(value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        break;
      case "percentage":
        if (parseFloat(value) < 0) {
          result =
            "(" +
            Math.round(-100 * parseFloat(value))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            "%)";
        } else {
          result =
            Math.round(100 * parseFloat(value))
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
        }
        break;
      case "ratio":
        result =
          (Math.round(parseFloat(value) * 10) / 10)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "x";
        break;
      case "money":
        result =
          "$" +
          Math.round(parseFloat(value))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        break;
    }

    return result;
  };

  function onGridReady(params: ColumnResizedEvent) {
    params.api.sizeColumnsToFit();
  }

  return (
    <div className="balance-sheet-master">
      {!(state.loadedColumns && state.loadedData) ? (
        <div className="loading-div">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2x"
            spin={true}
            style={{ margin: "0 auto" }}
          />
        </div>
      ) : (
        <div className="balance-sheet-tables-div">
          <div className="balance-sheet-table-title">Balance Sheet</div>
          <div className="balance-sheet-table-subtitle">(in Thousands)</div>
          <div className="balance-sheet-table">
            <AgGridReact
              domLayout={"autoHeight"}
              defaultColDef={{
                cellClass: "text",
                headerClass: "column",
                resizable: true,
              }}
              onGridReady={onGridReady}
              rowClassRules={{
                highlight: function (params: any) {
                  let differentColors: string[] = [
                    "Current Assets",
                    "LT Assets",
                    "Total Assets",
                    "Current Liabilities",
                    "LT Liabilities",
                    "Total Liabilities",
                    "Total Equity",
                  ];
                  return differentColors.includes(params.data.Account);
                },
              }}
              rowData={state.data}
              columnDefs={state.columns}
            />
          </div>

          <div className="balance-sheet-table">
            <AgGridReact
              domLayout={"autoHeight"}
              defaultColDef={{
                cellClass: "text",
                headerClass: "column",
                resizable: true,
              }}
              onGridReady={onGridReady}
              rowData={state.memo}
              columnDefs={state.columnsMemo}
            />
          </div>
        </div>
      )}
    </div>
  );
};
