/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./InputSlider.scss";

interface InputSliderProps {
  /**
   * startingValue: the starting value of the input slider.
   */
  startingValue: number;
  /**
   * leadingSymbol: symbol to the left of the input box.
   */
  leadingSymbol: string;
  /**
   * trailingSymbol: symbol to the right of the input box.
   */
  trailingSymbol: string;
  /**
   * label: label of the input
   */
  label: string;
  /**
   * showLabel: boolean value that determines if the label is shown or not
   */
  showLabel: boolean;
  /**
   * onValueChange: function that returns the new value of the input and slider, and the "identity" of this slider.
   * @param value
   * @param index
   * @returns
   */
  onValueChange: (value: number) => void;
  /**
   * labelVerticality: if true, the label is vertical and located to the side of the input. If false, the label is horizontal
   * and located at the top
   */
  labelVerticality: boolean;
}

interface InputSliderState {
  value: number;
  min: number;
  max: number;
  step: number;
}

/**
 * Component that displays a value in Input and Slider format
 */
export const InputSlider = (props: InputSliderProps) => {
  const [state, setState] = useState<InputSliderState>({
    value: 0,
    min: 0,
    max: 0,
    step: 0,
  });

  useEffect(() => {
    let half = props.startingValue * 0.5;
    let oneAndAHalf = props.startingValue * 1.5;

    let min = 0;
    let max = 0;

    if (props.startingValue === 0) {
      min = -100;
      max = 100;
    } else {
      min = half < oneAndAHalf ? half : oneAndAHalf;
      max = half > oneAndAHalf ? half : oneAndAHalf;
    }

    let step = props.startingValue === 0 ? 0.5 : props.startingValue * 0.015;

    setState({
      value: props.startingValue,
      min: min,
      max: max,
      step: step,
    });
  }, []);

  function handleChange(eventValue: number): void {
    setState({ ...state, value: eventValue });
    props.onValueChange(eventValue);
  }

  return (
    <div
      className="input-slider-master"
      style={{ display: props.labelVerticality ? "flex" : "block" }}
    >
      <div
        className={props.labelVerticality ? "input-slider-label" : ""}
        style={{ visibility: props.showLabel ? "visible" : "hidden" }}
      >
        {props.label}
      </div>
      <div className="input-slider-inputs">
        <div className="input-group input-group-sm input-slider-box">
          <div
            className="input-group-text"
            style={{
              visibility:
                props.leadingSymbol.length !== 0 ? "visible" : "hidden",
            }}
          >
            {props.leadingSymbol}
          </div>
          <input
            className="form-control projections-input-box-input input-slider-input-box-input"
            type="number"
            value={state.value}
            onChange={(e) => handleChange(e.target.valueAsNumber)}
          />
          <div className="input-group-text">{props.trailingSymbol}</div>
        </div>
        <div className="input-slider-slider">
          <input
            value={state.value}
            className="form-range"
            type="range"
            min={state.min}
            max={state.max}
            onChange={(e) => handleChange(e.target.valueAsNumber)}
            step={state.step}
          />
        </div>
      </div>
    </div>
  );
};
